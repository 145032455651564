<template>
  <div class="bg-white shadow-sm rounded-lg p-4">
    <div class="row">
      <div v-if="discriminator === 'administrator'" class="col-3 ml-0 p-0">
        <multiselect
          v-model="oficeFilter"
          @input="filterData"
          :options="offices"
          :multiple="true"
          :close-on-select="false"
          :clear-on-select="false"
          :preserve-search="true"
          placeholder="Select Offices"
          label="office_name"
          track-by="id"
          :select-label="''"
          :deselect-label="''"
          @open="openBackgroundOpacity"
          @close="closeBackgroundOpacity"
        >
          <template slot="selection" slot-scope="{ values, search, isOpen }"
            ><span
              class="multiselect__single"
              v-if="values.length &amp;&amp; !isOpen"
              >{{
                values
                  .map((item) => {
                    return item.office_name;
                  })
                  .join(", ")
              }}</span
            ></template
          >
        </multiselect>
      </div>
    </div>
    <el-table
      :data="
        this.tableData.filter(
          (data) =>
            !search ||
            data.id.toString().toLowerCase().includes(search.toLowerCase()) ||
            data.corporate.name.toLowerCase().includes(search.toLowerCase()) ||
            data.startDate.toLowerCase().includes(search.toLowerCase()) ||
            data.form.name.toLowerCase().includes(search.toLowerCase()) ||
            data.frequency.name.toLowerCase().includes(search.toLowerCase())
        )
      "
      class="w-100"
    >
      <el-table-column
        sortable
        prop="corporate.name"
        label="Client Name"
      ></el-table-column>
      <el-table-column sortable prop="startDate" label="Started Sales Tax">
        <template slot-scope="scope">{{
          scope.row.startDate | moment("MM-DD-YYYY")
        }}</template>
      </el-table-column>
      <el-table-column sortable prop="form.name" label="Form"></el-table-column>
      <el-table-column
        sortable
        prop="frequency.name"
        label="Frequency"
      ></el-table-column>
      <el-table-column sortable prop="active" label="Client Status">
        <template slot-scope="scope">{{
          scope.row.active === 1 ? "Active" : "Inactive"
        }}</template>
      </el-table-column>
      <el-table-column
        label="Operations"
        v-if="this.$store.getters.discriminator === 'administrator'"
      >
        <template slot="header" slot-scope="scope">
          <el-input
            v-model="search"
            placeholder="Search..."
            class="p-0"
            :key="scope.row"
          />
        </template>
        <template slot-scope="scope">
          <center>
            <el-button-group>
              <el-button
                size="mini"
                icon="el-icon-edit"
                @click="update(scope.row, scope.$index)"
              ></el-button>
              <el-button
                size="mini"
                icon="el-icon-delete"
                @click="remove(scope.row, scope.$index)"
              ></el-button>
            </el-button-group>
          </center>
        </template>
      </el-table-column>
    </el-table>
    <el-button
      class="fixed-bottom new-register"
      type="success"
      @click="addClient"
      >Add New Client</el-button
    >

    <el-drawer
      title="New Sales Tax Client"
      :visible.sync="component.drawer"
      :before-close="handleClose"
      direction="rtl"
      size="500px"
    >
      <div class="pt-3 pl-5 pr-5 pb-5">
        <component-clients-sale-tax
          v-on:refresh="load($event)"
          :data="component.data"
          :key="component.render"
        />
      </div>
    </el-drawer>
  </div>
</template>

<script>
import clientSaleTax from "@/services/api/clientSaleTax";
import Multiselect from "vue-multiselect";
import office from "@/services/api/office";
import officeUser from "@/services/api/officeUser";
import ComponentClientsSaleTax from "@/components/admin/ClientsSaleTax";
export default {
  components: {
    ComponentClientsSaleTax,
    Multiselect,
  },
  data() {
    return {
      data: [],
      tableData: [],
      oficeFilter: [],
      offices: [],
      discriminator: "",
      search: "",
      component: {
        data: null,
        drawer: false,
        render: 0,
      },
      index: null,
    };
  },
  mounted() {
    switch (this.$store.getters.discriminator) {
      case "administrator":
        this.discriminator = "administrator";
        office.get().then((response) => {
          this.offices = response;
        });
        break;
      case "office":
      case "employee": {
        this.discriminator = "office";
        let idOffice = null;
        officeUser.getOffice(this.$store.getters.id).then((response) => {
          this.offices = response.map((item) => {
            idOffice = item.office.id;
            return {
              id: item.office.id,
              office_name: item.office.office_name,
            };
          });
        });
        break;
      }
    }
    clientSaleTax.get().then((response) => {
      this.data = Object.assign([], response);
      this.tableData = response;
    });
  },
  methods: {
    filterData() {
      let idOffices = this.oficeFilter.map((office) => {
        return office.id;
      });

      this.tableData = Object.assign([], this.data);

      if (idOffices.length > 0) {
        this.tableData = this.tableData.filter(
          (item) => idOffices.indexOf(item.corporate.officeId) != -1
        );
      }
    },
    load(event) {
      this.component.drawer = false;
      if (event.update) {
        this.tableData.splice(this.index, 1, event.data);
      } else {
        this.tableData.unshift(event.data);
      }
    },
    handleClose(done) {
      this.$confirm("Are you sure you want to close this?")
        .then((_) => {
          done();
        })
        .catch((_) => {});
    },
    addClient() {
      this.component.drawer = true;
      this.component.render += 1;
      this.component.data = null;
    },
    update(row, index) {
      this.index = index;
      //
      this.component.drawer = true;
      this.component.render += 1;
      this.component.data = row;
    },
    remove(row, index) {
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#67C23A",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.value) {
          clientSaleTax
            .delete(row)
            .then((response) => {
              this.tableData.splice(index, 1);
            })
            .catch(() => {
              this.$message({
                message: "Opps... Something wrong",
                type: "error",
                customClass: "message-error",
              });
            });
        }
      });
    },
  },
};
</script>
